/**
 * Calendar
 */
export const CALENDAR_CREATED_PENDING = "CALENDAR_CREATED_PENDING";
export const CALENDAR_CREATED_SUCCESS = "CALENDAR_CREATED_SUCCESS";
export const CALENDAR_CREATED_ERROR = "CALENDAR_CREATED_ERROR";

export const CALENDAR_FETCHED_PENDING = "CALENDAR_FETCHED_PENDING";
export const CALENDAR_FETCHED_SUCCESS = "CALENDAR_FETCHED_SUCCESS";
export const CALENDAR_FETCHED_ERROR = "CALENDAR_FETCHED_ERROR";

export const CALENDAR_ADDED_USER_PENDING = "CALENDAR_ADDED_USER_PENDING";
export const CALENDAR_ADDED_USER_SUCCESS = "CALENDAR_ADDED_USER_SUCCESS";
export const CALENDAR_ADDED_USER_ERROR = "CALENDAR_ADDED_USER_ERROR";

export const CALENDAR_IMPORTED_SUCCESS = "CALENDAR_IMPORTED_SUCCESS";

/**
 * Time
 */
export const TIME_ADDED_PENDING = "TIME_ADDED_PENDING";
export const TIME_ADDED_SUCCESS = "TIME_ADDED_SUCCESS";
export const TIME_ADDED_ERROR = "TIME_ADDED_ERROR";

export const TIME_ADDED_DUPLICATE = "TIME_ADDED_DUPLICATE";

export const TIMES_FETCHED_PENDING = "TIMES_FETCHED_PENDING";
export const TIMES_FETCHED_SUCCESS = "TIMES_FETCHED_SUCCESS";
export const TIMES_FETCHED_ERROR = "TIMES_FETCHED_ERROR";

export const TIME_REMOVED_PENDING = "TIME_REMOVED_PENDING";
export const TIME_REMOVED_SUCCESS = "TIME_REMOVED_SUCCESS";
export const TIME_REMOVED_ERROR = "TIME_REMOVED_ERROR";

export const TIMES_FILTER_BY_USER_PENDING = "TIMES_FILTER_BY_USER_PENDING";

/**
 * User
 */
export const USER_CREATED_PENDING = "USER_CREATED_PENDING";
export const USER_CREATED_SUCCESS = "USER_CREATED_SUCCESS";
export const USER_CREATED_ERROR = "USER_CREATED_ERROR";

export const USER_UPDATED_PENDING = "USER_UPDATED_PENDING";
export const USER_UPDATED_SUCCESS = "USER_UPDATED_SUCCESS";

export const USER_SET_CURRENT_PENDING = "USER_SET_CURRENT_PENDING";

/**
 * Auth
 */
export const AUTH_CODE_ADDED_SUCCESS = "AUTH_CODE_ADDED_SUCCESS";
export const AUTH_CODE_REMOVED_SUCCESS = "AUTH_CODE_REMOVED_SUCCESS";

/**
 * Event
 */
export const EVENT_ADDED_PENDING = "EVENT_ADDED_PENDING";

export const EVENT_SUBMITTED_PENDING = "EVENT_SUBMITTED_PENDING";
export const EVENT_SUBMITTED_SUCCESS = "EVENT_SUBMITTED_SUCCESS";
export const EVENT_SUBMITTED_ERROR = "EVENT_SUBMITTED_ERROR";

/**
 * Error
 */
export const ERROR_ADDED = "ERROR_ADDED";
export const ERROR_REMOVED = "ERROR_REMOVED";

/**
 * Success
 */
export const SUCCESS_ADDED = "SUCCESS_ADDED";
export const SUCCESS_REMOVED = "SUCCESS_REMOVED";